<template>
    <div class="bbb disease">
        <van-nav-bar
        title="请选择疾病"
        left-arrow
        @click-left="onClickLeft"
        />
      <div id="Interrogation" style="height: calc(100% - 262px);" class=" time_slot">
        <van-search v-model="search_value" @input="loadList" clearable placeholder="请输入病症关键词"/>
        可能疾病：
        <el-tag
        v-for="tag in disease"
        :key="tag"
        @click="diseaseCli(tag)"
        >
        {{tag}}
      </el-tag>
      <div style="height: 10%;" v-if="choice.length == 0">
            <el-alert
              title="请从下表中选择病症"
              type="warning"
              center
              :closable="false"
              show-icon>
            </el-alert>
          </div>
        <div class="margin_top diseaseList" v-if="tableData.length !== 0" >
            <van-cell-group>
                <van-cell :title="item.title" v-for="(item,index) of tableData" clickable @click="group(item)">
                    <van-icon
                    slot="right-icon"
                    v-show="choice_change(item.id)"
                    name="success"
                    :size="16"
                    color="#32ae57"
                    />
                </van-cell>
            </van-cell-group>
        </div>
        <div  v-else>
          <van-nodata   :figure="imgUrl[3]" title="暂无疾病信息" />
        </div>
        <footer_/>
      </div>
      <div class="choice_disease">
        已选择疾病：{{ choice.length == 0 ? '无' : ''}} <el-tag
        v-for="tag in choice"
        :key="tag.disease"
        closable
        @close="handleClose(tag)"
        >
        {{tag.title}}
      </el-tag>
     
      </div>
      <div class=" padding_05 disease_btn">
          <van-button @click="determine" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>病症选择完毕</van-button>
      </div>
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  import base from './api/base.js'
  import mixin from "@/utils/mixin";
  export default {
    mixins: [mixin],
    components: {
      footer_,
    },
    data() {
      return {
        tableData:[],
        backups:[],
        choice:[],
        search_value:'',
        disease:[]
      }
    },
    methods: {
        handleClose(item){
          let index = this.choice.findIndex(t=>{
              return t.id == item.id
          })
          this.choice.splice(index,1)
        },
        group(item){
            let ifstate=this.choice.findIndex((t)=>{
                return t.id == item.id;
            })
            if(ifstate !== -1){
                this.choice.splice(ifstate,2)
            }else if(this.choice.length <=4){
                this.choice.push(item)
            }else{
              this.$message.error('最多可选择5个病种');
            }
        },
        choice_change(id){
            return this.choice.find(item=>{
                return item.id == id
            })
        },
        onClickLeft(){
          console.log(this.choice)
          let choice = []
          this.choice.forEach(element => {
            choice.push(element.title)
          });
          if(choice.length !==0){
            const h = this.$createElement;
            this.$msgbox({
              title: '提醒',
              message: h('p', { style: 'text-align: center' }, [
                  h('span', null, '您当前选择疾病'),
                  h('i', { style: 'font-weight: 800' },choice.join(',')),
        
              ]),
              showCancelButton: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              beforeClose: (action, instance, done) => {
                if(action == 'confirm'){
                    this.determine()
                  
                  }else{
                    this.$router.back(-1)
                  }
              }
            })
          }else{
            const h = this.$createElement;
            this.$msgbox({
              title: '提醒',
              message: h('p', { style: 'text-align: center' }, [
                  h('span', null, '您当前暂未选择疾病'),
        
              ]),
              showCancelButton: true,
              confirmButtonText: '确定',
              beforeClose: (action, instance, done) => {
                if(action == 'confirm'){
                  this.$router.back(-1)
                  
                  }else{
                  }
              }
            })
          }
          
        },
        diseaseCli(item){
          this.search_value = item
          this.loadList()
        },
        loadList(){
              let params = {
                  search_db: 'DRGDatabase',
                  query_type: this.search_value == '' ? 'equal' : 'like',
                  start:0,
                  length:50,
              };
           
              let data = {
                  query: {
                    title:this.search_value == '' ? undefined : this.search_value
                  }
              };

              let promise = this.$api.article.query(params, data).then(res => {
                  this.tableData = res.data.result
                  this.backups = JSON.parse(JSON.stringify(res.data.result))
              });
        },
        determine(){
            if(this.$route.query.type == 'past'){
              sessionStorage.setItem('past', JSON.stringify(this.choice))
            }else if(this.$route.query.type == 'disease'){
              console.log(this.choice)
              sessionStorage.setItem('disease', JSON.stringify(this.choice))
            }else{
              sessionStorage.setItem('family', JSON.stringify(this.choice))
            }
            this.$router.back(-1)
        }
    },
    created() {
      this.loadList()
      this.Drugs = JSON.parse(sessionStorage.getItem('Drugs'))
      let arr = [];
      let promises = [];
      if(this.Drugs){
        this.Drugs.forEach(item => {
          let params = {
              search_db: 'DiseaseSymptomDrug',
              query_type: 'like',
          };

          let data = {
              query: {
                  drug: item.name
              }
          };

          let promise = this.$api.article.query(params, data).then(res => {
              res.data.result.forEach(element => {
                  arr.push(element);
              });
          });

          promises.push(promise);
      });
      }
      if(sessionStorage.getItem(this.$route.query.type)){
        this.choice = JSON.parse(sessionStorage.getItem(this.$route.query.type))

      }
      // 使用Promise.all等待所有异步请求完成
      Promise.all(promises).then(() => {
          let disease = []
            arr.forEach(element => {
                element.disease.split(',').forEach(item => {
                    if(disease.indexOf(item) == -1){
                      if(disease.length<10){
                      disease.push(item)

                      }
                    }
                });
            });
          this.disease = disease
      });
      
    },
    mounted(){
   

    }
  }
  </script>
  <style  lang="less">
  .disease_btn{
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
  }
  .choice_disease{
    padding: 0 0.5rem 0.5rem 0.5rem;
    .el-tag{
      font-size: 17px;
    }
  }
  .padding_05{
    padding: 0.5rem;
  }
  .disease .van-cell__title-text{
    width: 100%;
  }
  .disease.van-cell__label{
    white-space:revert;
  }
  .disease .van-field__control--right{
    text-align:left;
  }
  .price{
    color: #F56C6C;
    font-size: 20px;
    font-weight: 600;
  }
  .van-multi-ellipsis--l1, .van-multi-ellipsis--l2, .van-multi-ellipsis--l3{
    overflow:visible !important;
    text-overflow:clip !important;
    -webkit-line-clamp:999 !important;
  }
  .van-text-box__title {
    font-weight: 500;
    font-size: 15px !important;
  }
  </style>
  <style lang="less" scoped>
  .diseaseList{
    height: 30rem;
    overflow-y: auto;
  }
  .drugs_img{
    width: 130px;
    img{
      width: 100%;
    }
  }
  </style>
  <style>
  .price_number{
    width:100%;
    display:flex;
    justify-content:space-between;
  }
</style>
